import { ArrowBottomSvg, ArrowSvg, ArrowTopSvg } from '@/assets/svg/icons'
import { SortState, TableHeaderField } from '@/pages/dashboard/model/types'
import classNames from 'classnames'
import { Fragment } from 'react/jsx-runtime'
import styles from './styles.module.scss'

export function TableHeader({
	fields,
	sort,
	setSort,
	hasAnyRowsOpen,
	toggleRows,
}: {
	fields: Array<TableHeaderField>
	sort: SortState
	setSort: React.Dispatch<React.SetStateAction<SortState>>

	hasAnyRowsOpen: boolean
	toggleRows: (openAll: boolean) => void
}) {
	return (
		<thead className={styles.header}>
			<tr>
				{/* TODO: Reimplement it */}
				<th
					align='center'
					onClick={() => {
						toggleRows(!hasAnyRowsOpen)
					}}
				>
					<span
						className={classNames(styles['openAllButton'], {
							[styles['openAllButtonActive']]: hasAnyRowsOpen,
						})}
					>
						<ArrowSvg />
					</span>
				</th>

				{fields.map(field => {
					return (
						<Fragment key={field.name}>
							<th align='left'>{field.name}</th>
							<th align='right' className={styles.tableBorder}>
								{field.sortable && (
									<span className={styles['sort-arrows']}>
										<ArrowTopSvg
											className={
												field.field === sort?.field && sort.order === 'ASC'
													? styles['active']
													: ''
											}
											onClick={() => {
												if (
													sort?.field === field.field &&
													sort.order === 'ASC'
												) {
													setSort(null)
												} else {
													setSort({
														field: field.field,
														order: 'ASC',
													})
												}
											}}
										/>
										<ArrowBottomSvg
											className={
												field.field === sort?.field && sort.order === 'DESC'
													? styles['active']
													: ''
											}
											onClick={() => {
												if (
													sort?.field === field.field &&
													sort.order === 'DESC'
												) {
													setSort(null)
												} else {
													setSort({
														field: field.field,
														order: 'DESC',
													})
												}
											}}
										/>
									</span>
								)}
							</th>
						</Fragment>
					)
				})}
			</tr>
		</thead>
	)
}
