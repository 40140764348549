import { getCurrencies } from '@/shared/api'
import classNames from "classnames"
import { format } from "date-fns"
import { memo, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { AiOutlineReload } from "react-icons/ai"
import { PiCurrencyDollarBold, PiCurrencyEurBold } from "react-icons/pi"
import styles from "./CurrencyWidget.module.scss"

/**
 * Counts milliseconds until the next minute starts to keep the clock in sync
 * @param ms Current timestamp in ms
 * @returns 
 */
function msToNextMinute(ms: number){
  return (Math.ceil(ms / 60000) * 60000) - ms;
}



const CurrencyWidget = memo( function CurrencyWidget() {
  const timerRef = useRef<NodeJS.Timeout>()
  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState<
    | {
        usd: number;
        eur: number;
      }
    | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState(new Date());

  // Made to calculate time only once a minute
  function refreshClock() {
    const currentDate  = new Date();
    setDate(currentDate);
    const mstonewmin = msToNextMinute(Date.now());
    timerRef.current = setTimeout(refreshClock, mstonewmin )
  }

  const refreshCurrencies = async () => {
    setLoading(true)
    const response = await getCurrencies();
    setCurrencies(response);
    setLoading(false)
  };

  useEffect(() => {
    refreshCurrencies();
  }, []);

  // Update clock
  useEffect(() => {
    refreshClock();
    return function cleanup() {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  // /api/

  return (
    <div className={styles["container"]}>
      <div className={classNames(styles["reload-button"], {
          [styles["reload-button-loading"]]: loading
      })} onClick={refreshCurrencies}>
        <AiOutlineReload size={24} />
      </div>

      <div className={styles["current-time"]}>
        <div>{t("sidebar.currencyWidget.localTime", "Местное время")}:</div>
        <div>{format(date, "HH:mm")}</div>
      </div>
      <div className={styles["current-curse"]}>
        <div>{t("sidebar.currencyWidget.currencyRates", "Курсы валют")}:</div>
        {currencies && (
          <div className={styles["currency-list"]}>
            <div title="Доллар" className={styles["usd"]}><PiCurrencyDollarBold /> <span>{currencies.usd}</span></div>
            <div title="Евро" className={styles["eur"]}><PiCurrencyEurBold />  <span>{currencies.eur}</span></div>
          </div>
        )}
      </div>
    </div>
  );
});

export default CurrencyWidget;