import { useEffect, useState } from 'react'

import { GetCurrentUser } from '@/shared/api'
import { useLocation, useNavigate } from 'react-router'
import { Router } from './router'

export function App() {
	const navigate = useNavigate()
	const location = useLocation()

	const [loading, setLoading] = useState(true)

	// Check if user is logged in
	useEffect(() => {
		;(async () => {
			const user = await GetCurrentUser()

			if (!user) {
				navigate('/login/' + location.search)
				setLoading(false)
				return
			}
			setLoading(false)
		})()
	}, [])

	if (loading) {
		return <div></div>
	}

	return (
		<div className='app'>
			<Router />
		</div>
	)
}
