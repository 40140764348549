import { LanguageSwitcher } from '@/features/language-switcher'
import { LogoutButton } from '@/features/logout-button'
import { SearchPrompt } from '@/features/search-prompt'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSidebar from 'react-sidebar'
import { ArrowCircleRight } from '../../assets/svg/icons'

import ContactButton from '@/components/ContactButton/ContactButton'
import ContactForm from '@/components/contactForm/ContactForm'
import CurrencyWidget from '@/components/CurrencyWidget/CurrencyWidget'
import ForwarderInput from '@/components/ForwarderInput/ForwarderInput'
import { QuickFilters } from '@/components/QuickFilters/QuickFilters'
import { RadioSwitcher } from '@/components/RadioSwitcher/RadioSwitcher'
import { SidebarProps } from './model/types'
import styles from './styles.module.scss'

export function Sidebar({
	user,
	onSearch,
	onSearchInput,
	searchValue,
	onArchiveChange,
	isArchive,
	filters,
	setFilters,
	isOpen,
	setIsOpen,
}: SidebarProps) {
	const { t } = useTranslation()

	const [wide, setWide] = useState(false)

	function checkSize() {
		const mql = window.matchMedia(`(min-width: 769px)`)
		// Set wide mode
		setWide(mql.matches)
	}

	useEffect(() => {
		checkSize()
		window.addEventListener('resize', checkSize)

		// cleanup this component
		return () => {
			window.removeEventListener('resize', checkSize)
		}
	}, [])

	return (
		<div className={classNames(styles['sidebar-container'])}>
			<ReactSidebar
				sidebar={
					<div className={styles['sidebarContainer']}>
						<div className={styles['sidebarFlexStart']}>
							<div className={styles['welcome-message']}>
								<div className={styles['welcome-message-text']}>
									{t('sidebar.welcome', 'Добро пожаловать')}, <br />
									<b>{user?.companyName}</b>
								</div>
								<LanguageSwitcher className={styles['language-switcher']} />
							</div>

							<CurrencyWidget />

							<RadioSwitcher onChange={onArchiveChange} value={isArchive} />
							<SearchPrompt
								onSearch={onSearch}
								onInput={onSearchInput}
								value={searchValue}
							/>

							<div className={styles.containerQuickFilters}>
								<div className={styles.qfilters}>
									{t('sidebar.quickFilters.title', 'Быстрые фильтры')}:
								</div>
								<QuickFilters filters={filters} setFilters={setFilters} />
							</div>

							<ContactForm />
							<ForwarderInput />
							<div style={{ marginTop: '22px' }}>
								<span className={styles['contactSpan']}>
									{t(
										'sidebar.contact.contactInfoTitle',
										'Контактная информация'
									)}
									:
								</span>
							</div>

							<ContactButton
								title={t(
									'sidebar.contact.mainManagerButton',
									'Ведущий менеджер'
								)}
								name={user?.manager_name}
								email={user?.manager_email}
								phone={user?.manager_phone_number}
							/>
							<ContactButton
								title={t(
									'sidebar.contact.departmentManager',
									'Руководитель отдела'
								)}
								name={user?.director_name}
								email={user?.director_email}
								phone={user?.director_phone_number}
							/>
						</div>

						<div className={styles['sidebarFlexEnd']}></div>
						<LogoutButton />
					</div>
				}
				open={isOpen}
				onSetOpen={setIsOpen}
				sidebarClassName={styles['sidebar-content']}
				overlayClassName={styles['overlay']}
				contentClassName={styles['sidebar-opener']}
				docked={wide}
				styles={{
					root: {
						// This is to fix the scrollbar not being touchable
						width: '300px',
					},
				}}
			>
				<div
					className={styles['mobile-sidebar']}
					onClick={() => setIsOpen && setIsOpen(!isOpen)}
				>
					<ArrowCircleRight />
				</div>
			</ReactSidebar>
		</div>
	)
}
