

import { ForwarderEmail, GetCurrentUser } from '@/shared/api'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { toast } from 'react-toastify'
import { IconsExpeditor } from '../../assets/svg/icons'
import styles from "./ForwarderInput.module.scss"
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[5],
        zIndex: 200,
        fontFamily: 'Fira Sans',
        fontStyle: "normal",
        fontWeight: 500,
        padding: "10px 10px",
        borderRadius: "3px",
        fontSize: "13px",
        lineHeight: "16px"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'rgb(255 255 255 / 100%)',
    }
  }));

const ForwarderInput = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState('');
    useEffect(() => {
        async function getForwarderEmailUser() {
            const response = await GetCurrentUser();

            if (response?.forwarder_email) {
                setValue(response.forwarder_email);
            }
        }
        getForwarderEmailUser()
    }, [])

    




    const processForm: React.FormEventHandler<HTMLFormElement> = async function (e) {

        e.preventDefault();

        const form = e.currentTarget;
        const formdata = new FormData(form)

        const email = formdata.get("email");

        const response = await ForwarderEmail(email as string);
        if (response) {
            toast(t("expeditorInput.toast.success","Email экспедитора успешно сохранен."), {
                type: "success"
            })
        } else {
            toast(t("expeditorInput.toast.error","Ошибка сохранения email экспедитора."), {
                type: "error"
            })
        }
    }

    return (
        <form onSubmit={processForm}>
            <div className={styles.container}>
                <span className={styles.titleEmail}>E-mail</span>
                <div className={styles.containerInput}>
                    <input className={styles.input}
                        type="email"
                        name="email"
                        // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        placeholder={t("expeditorInput.emailPlaceholder","Введите e-mail экспедитора...")}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                    <LightTooltip arrow placement="top" title={t("expeditorInput.emailTooltip","Введите адрес почты для оперативного информирования о выпуске ДТ")}>
                        <div className={styles.icons} ><IconsExpeditor /></div>
                    </LightTooltip>
                
                </div>
                <div>
                    <button className={styles.button} type="submit">{t("expeditorInput.saveButton","Сохранить")}</button>
                </div>
                
            </div>
        </form>
    )
}

export default ForwarderInput;