// Quick filters sidebar
import { IHistoryItem } from '@/shared/api'
import { useTranslation } from "react-i18next"
import styles from "./QuickFilters.module.scss"


export const PossibleFilters = [
  {
    title: "В море",
    name: "sea",
    filter: (data: IHistoryItem) => {
        // Вышел в море но не прибыл в порт
        return data.date_vihodvmore !== null && data.date_pributievport == null
    },
  },
  {
    title: "На таможне",
    name: "customs",
    filter: (data: IHistoryItem) => {
        // Подача гтд начата, но не завершена
        return data.date_vipuskgtd === null && data.date_podachagtd != null
    },
  },
  {
    title: "На ж/дороге",
    name: "railroad",
    filter: (data: IHistoryItem) => {
        // Отправка по жд осуществилась но еще не завершена
        return data.date_otpravkapojd !== null && data.date_arrived_to_station === null
    },
  },
  {
    title: "Прибыл",
    name: "arrived",
    filter: (data: IHistoryItem) => {
        // Прибыла ли на станцию
        return data.date_arrived_to_station !== null;
    },
  },
];


export const QuickFilters = (props: {
  filters: typeof PossibleFilters;
  setFilters: React.Dispatch<React.SetStateAction<typeof PossibleFilters>>;
}) => {
  const { t } = useTranslation();

  const filterTitles = {
    sea: t("sidebar.quickFilters.sea", "В море"),
    customs: t("sidebar.quickFilters.customs", "На таможне"),
    railroad: t("sidebar.quickFilters.railroad", "На ж/дороге"),
    arrived: t("sidebar.quickFilters.arrived", "Прибыл"),
  }; 

  return (
    <>
      {/* Special all case */}
      <div
        style={{ display: "flex" }}
        onClick={() => {
          props.setFilters((filters) => {
            
            if (props.filters.length > 0) {
              return [];
            } else {
              return PossibleFilters;
            }
          });
        }}
      >
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.26514"
            y="1.81415"
            width="14.9525"
            height="15"
            rx="3"
            stroke="white"
            strokeWidth="2"
          />
          {props.filters.length === PossibleFilters.length && (
            <path
              d="M17.5342 3.01674C17.6447 2.90274 17.7765 2.81218 17.922 2.75035C18.0675 2.68852 18.2237 2.65668 18.3814 2.65668C18.5392 2.65668 18.6954 2.68852 18.8409 2.75035C18.9864 2.81218 19.1182 2.90274 19.2287 3.01674C19.6915 3.48987 19.698 4.25442 19.2448 4.73574L9.67553 16.1794C9.56689 16.3001 9.43504 16.3971 9.28806 16.4644C9.14109 16.5318 8.98207 16.5681 8.82073 16.5712C8.6594 16.5743 8.49914 16.544 8.34977 16.4823C8.20039 16.4205 8.06504 16.3286 7.95199 16.2121L2.12917 10.2431C1.9046 10.0114 1.77881 9.69988 1.77881 9.3754C1.77881 9.05093 1.9046 8.7394 2.12917 8.50772C2.23961 8.39372 2.37143 8.30315 2.51691 8.24133C2.66238 8.1795 2.81858 8.14765 2.97637 8.14765C3.13416 8.14765 3.29037 8.1795 3.43584 8.24133C3.58132 8.30315 3.71313 8.39372 3.82358 8.50772L8.76278 13.5714L17.5019 3.05276C17.5119 3.04011 17.5227 3.02808 17.5342 3.01674Z"
              fill="white"
            />
          )}
        </svg>
        <span style={{ marginLeft: "5px" }} className={styles.quickFiltersRow}>{t("sidebar.quickFilters.all", "Все" )}</span>
      </div>
      {PossibleFilters.map((filter) => {
        return (
          <div key={filter.name}
            style={{ display: "flex" }}
            className={styles.quickFiltersRow}
            onClick={() => {
              props.setFilters((filters) => {
                const isThere = filters.find((i) => i.name === filter.name);
                if (filter.name === "all") {
                  if (isThere) {
                    return [];
                  } else {
                    return PossibleFilters;
                  }
                } else {
                  if (isThere) {
                    return filters.filter((i) => i.name !== filter.name);
                  } else {
                    return [...filters, filter];
                  }
                }
              });
            }}
          >
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1.26514"
                y="1.81415"
                width="14.9525"
                height="15"
                rx="3"
                stroke="white"
                strokeWidth="2"
              />
              {props.filters.find((i) => i.name === filter.name) && (
                <path
                  d="M17.5342 3.01674C17.6447 2.90274 17.7765 2.81218 17.922 2.75035C18.0675 2.68852 18.2237 2.65668 18.3814 2.65668C18.5392 2.65668 18.6954 2.68852 18.8409 2.75035C18.9864 2.81218 19.1182 2.90274 19.2287 3.01674C19.6915 3.48987 19.698 4.25442 19.2448 4.73574L9.67553 16.1794C9.56689 16.3001 9.43504 16.3971 9.28806 16.4644C9.14109 16.5318 8.98207 16.5681 8.82073 16.5712C8.6594 16.5743 8.49914 16.544 8.34977 16.4823C8.20039 16.4205 8.06504 16.3286 7.95199 16.2121L2.12917 10.2431C1.9046 10.0114 1.77881 9.69988 1.77881 9.3754C1.77881 9.05093 1.9046 8.7394 2.12917 8.50772C2.23961 8.39372 2.37143 8.30315 2.51691 8.24133C2.66238 8.1795 2.81858 8.14765 2.97637 8.14765C3.13416 8.14765 3.29037 8.1795 3.43584 8.24133C3.58132 8.30315 3.71313 8.39372 3.82358 8.50772L8.76278 13.5714L17.5019 3.05276C17.5119 3.04011 17.5227 3.02808 17.5342 3.01674Z"
                  fill="white"
                />
              )}
            </svg>
            <span style={{ marginLeft: "5px" }}>{filterTitles[filter.name]}</span>
          </div>
        );
      })}
    </>
  );
};
