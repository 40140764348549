import { useTranslation } from 'react-i18next'
import { TableHeaderField } from './types'


export const useTableHeaderFields = (): TableHeaderField[] => {
	const { t } = useTranslation()

	return [
			{
				name: t('dashboard.tableHeaders.requestNumber', '№ Заявки'),
				field: 'request_number',
				sortable: true,
			},
			{
				name: t('dashboard.tableHeaders.clientNumber', '№ клиента'),
				field: 'client_number',
				sortable: true,
			},
			{
				name: t('dashboard.tableHeaders.description', 'Описание груза'),
				field: 'description',
				sortable: true,
			},
			{
				name: t('dashboard.tableHeaders.senderName', 'Отправитель'),
				field: 'sender_name',
				sortable: true,
			},
	
			{
				name: t('dashboard.tableHeaders.containerId', 'Номер КТК'),
				field: 'container_id',
				sortable: true,
			},
			{
				name: t('dashboard.tableHeaders.dateActualForSite', 'Готовность'),
				field: 'readiness',
				sortable: true,
			},
			{
				name: t('dashboard.tableHeaders.dateVihodvmore', 'Выход в море'),
				field: 'date_vihodvmore',
				sortable: true,
			},
			{
				name: t('dashboard.tableHeaders.datePributievport', 'Прибытие'),
				field: 'date_pributievport',
				sortable: true,
			},
		]
}