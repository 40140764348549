export const apiPath = import.meta.env.VITE_API_PATH ?? "/cabinet/";


export const NotificationStatusMap = {
  "delivered" : "Доставлен",
  "onTrainStation" : "Прибытие на станцию",
  "containerOnRails" : "Отправка по ЖД",
  "waitingForRails" : "Выпуск с таможни",
  "customsClearance" : "Подача документов на таможне",
  "arrivalInPort" : "Прибытие в порт",
  "wentToSea" : "Выход в море",
  "uponTheSender" : "У отправителя",
  "custom" : "Custom"
}