import { format, parse } from "date-fns"
import { isEmpty } from './is-empty'

export function parse1CDate(str: string) {
	if (isEmpty(str)) {
		return undefined;
	} else {
		const date = parse(str, "dd.MM.yyyy", new Date());
		return date;
	}
}

export function parse1CDateTime(str: string) {
	if (isEmpty(str)) {
		return undefined;
	} else {
		const date = parse(str, "dd.MM.yyyy HH:mm:ss", new Date());
		return date;
	}
}

export function fdate(date?: Date | null) {
	if (date == null || date === undefined) {
		return ''
	}
	if (date.toString() === 'Invalid Date') {
		return ''
	}
	return format(date, 'yyyy-MM-dd')
}