import { useTranslation } from 'react-i18next'
import { FaSearch } from 'react-icons/fa'
import { SearchPromptProps } from './model/types'
import styles from './styles.module.scss'

export function SearchPrompt(props: SearchPromptProps) {
	const processInput: React.FormEventHandler<HTMLInputElement> = function (e) {
		const element = e.currentTarget
		if (props.onInput) {
			props.onInput(element.value)
		}
	}

	const { t } = useTranslation()

	return (
		<div className={styles.search}>
			<input
				type='search'
				onInput={processInput}
				placeholder={
					props.placeholder || t('sidebar.searchPlaceHolder', 'Поиск')
				}
				value={props.value}
			/>
			<button onClick={props.onSearch}>
				<FaSearch />
			</button>
		</div>
	)
}
