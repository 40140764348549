import { SortState } from '@/pages/dashboard/model/types'
import { useTableHeaderFields } from '@/pages/dashboard/model/use-table-header-fields'
import { IHistoryItem } from '@/shared/api'
import { TableHeader } from '../table-header'
import { TableRow } from '../table-row'
import styles from './styles.module.scss'

interface Props {
	sort: SortState
	setSort: React.Dispatch<React.SetStateAction<SortState>>
	openRows: Array<number>
	setOpenRows: React.Dispatch<React.SetStateAction<Array<number>>>
	setData: React.Dispatch<React.SetStateAction<Array<IHistoryItem>>>
	filteredItems: unknown[]
	sortedItems: unknown[]
}
export const TableWrapper = ({
	sort,
	setSort,
	openRows,
	setOpenRows,
	filteredItems,
	setData,
	sortedItems,
}: Props) => {
	return (
		<table className={styles.TableContainer}>
			<TableHeader
				fields={useTableHeaderFields()}
				sort={sort}
				setSort={setSort}
				hasAnyRowsOpen={openRows.length > 0}
				toggleRows={openAll => {
					if (openAll) {
						setOpenRows(filteredItems.map((item: IHistoryItem) => item.id))
					} else {
						setOpenRows([])
					}
				}}
			/>
			<tbody className={styles.tableContent}>
				{sortedItems.map((item: IHistoryItem) => {
					return (
						<TableRow
							key={item.id}
							data={item}
							isOpen={openRows.includes(item.id)}
							setOpenRows={setOpenRows}
							setData={setData}
						/>
					)
				})}
			</tbody>
		</table>
	)
}
