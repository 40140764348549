import { PossibleFilters } from '@/components/QuickFilters/QuickFilters'
import NotificationWidget from '@/components/notifications/NotificationWidget'
import { GetCurrentUser, GetHistory, IHistoryItem, IUser } from '@/shared/api'
import { Sidebar } from '@/widgets'
import classNames from 'classnames'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { SortState } from './model/types'
import styles from './styles.module.scss'
import { TableWrapper } from './ui/table/table-wrapper'

function empty(item: any) {
	return (
		item == null || item === undefined || item === ''
		// removeSpaces(item) == ""
	)
}

export function DashboardPage() {
	const [user, setUser] = useState<IUser | undefined>(undefined)

	const [filters, setFilters] =
		useState<typeof PossibleFilters>(PossibleFilters)

	const [isArchive, setIsArchive] = useState(false)
	const [sidebarActive, setSidebarActive] = useState(false)
	const [search, setSearch] = useState('')

	const [data, setData] = useState<Array<IHistoryItem>>([])
	const [openRows, setOpenRows] = useState<Array<number>>([])

	const [sort, setSort] = useState<SortState>(null)
	// Index the items
	const fuse = useMemo(() => {
		return new Fuse(data, {
			keys: [
				'container_id',
				'dislocation',
				'conosoment_number',
				'client_number',
				'comment',
				'description',
				'sender_name',
				'destination_city',
				'destination_port',
				'request_number',
				'gtd_number',
			],
			useExtendedSearch: true,
			location: 0,
			threshold: 0.1,
			distance: 0,
			ignoreLocation: true,
		})
	}, [data])

	const navigate = useNavigate()
	const location = useLocation()

	const filteredItems = useMemo(() => {
		let filtered = data

		// Filter by status
		filtered = filtered.filter(item => item.archive == isArchive)

		if (search != '') {
			filtered = fuse.search(search).map(item => item.item)
		}

		// Filter
		if (filters.length > 0 && filters.length < PossibleFilters.length) {
			filtered = filtered.filter(item => {
				let shouldBeShown = false
				filters.forEach(
					filter => (shouldBeShown = shouldBeShown || filter.filter(item))
				)
				return shouldBeShown
			})
		}

		return filtered
	}, [data, isArchive, search, filters])

	// Sort
	const sortedItems = useMemo(() => {
		if (sort == null) {
			return filteredItems
		}

		const sorted = [...filteredItems]

		const field = sort.field

		if (sort.order === 'ASC') {
			sorted.sort((a, b) => {
				const aEmpty = empty(a[field]);
				const bEmpty = empty(b[field]);
				if (aEmpty && !bEmpty) return 1;
				if (!aEmpty && bEmpty) return -1;
				if (aEmpty && bEmpty) return 0;

				if (a[field]! > b[field]!) {
					return 1
				} else if (a[field]! < b[field]!) {
					return -1
				} else {
					return 0
				}
			})
		} else {
			sorted.sort((a, b) => {
				// Send empty to the end of the list
				const aEmpty = empty(a[field]);
				const bEmpty = empty(b[field]);
				if (aEmpty && !bEmpty) return 1;
				if (!aEmpty && bEmpty) return -1;
				if (aEmpty && bEmpty) return 0;

				if (a[field]! > b[field]!) {
					return -1
				} else if (a[field]! < b[field]!) {
					return 1
				} else {
					return 0
				}
			})
		}

		return sorted
	}, [filteredItems, sort])

	// Check if user is logged in
	useEffect(() => {
		;(async () => {
			const user = await GetCurrentUser()

			if (!user) {
				navigate('/login/' + location.search)
				return
			}
			setUser(user)

			const response = await GetHistory()
			if (response) {
				setData(response)
				const params = new URLSearchParams(location.search)
				const search = params.get('s')
				const sortby = params.get('b')
				const sortorder = params.get('o')
				if (search) {
					setSearch(search)
				}
				// Restore sort
				if (sortby) {
					setSort({
						field: sortby as keyof IHistoryItem,
						order: sortorder === 'DESC' ? 'DESC' : 'ASC',
					})
				}
			}
		})()
	}, [])

	// Update the url with params
	useEffect(() => {
		const params = new URLSearchParams()
		if (sort) {
			params.set('b', sort.field)
			params.set('o', sort.order)
		}
		if (search) {
			params.set('s', search)
		}
		navigate('?' + params, {
			replace: true,
		})
	}, [sort, filters, search])

	return (
		<main className='main'>
			<NotificationWidget />

			<Sidebar
				user={user}
				isArchive={isArchive}
				onArchiveChange={setIsArchive}
				searchValue={search}
				onSearchInput={setSearch}
				setFilters={setFilters}
				filters={filters}
				isOpen={sidebarActive}
				setIsOpen={setSidebarActive}
			/>

			<div
				className={classNames(styles.content, {
					[styles.sidebarOpen]: sidebarActive,
				})}
			>
				<TableWrapper
					sortedItems={sortedItems}
					setData={setData}
					setOpenRows={setOpenRows}
					openRows={openRows}
					setSort={setSort}
					sort={sort}
					filteredItems={filteredItems}
				/>
			</div>
		</main>
	)
}
