import PopupMessageHost from '@/components/Popup/ConfirmPopup'
import ModalPortal from '@/components/Popup/ModalPortal'
import { ReactNode } from 'react'
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from 'react-toastify'


interface Props { 
	children: ReactNode
}
export const Providers = ( { children}: Props) => {
	return (
		<>
			<BrowserRouter basename={"/"}>
				{children}
			</BrowserRouter>
			<ToastContainer />
			<ModalPortal>
				<PopupMessageHost />
			</ModalPortal>
		</>
	)
}