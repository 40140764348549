import { Authenticate, GetCurrentUser } from '@/shared/api'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { LanguageSwitcher } from '../language-switcher'

export const AuthForm = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()

	const processForm: React.FormEventHandler<HTMLFormElement> = async function (
		e
	) {
		e.preventDefault()

		const form = e.currentTarget
		const formdata = new FormData(form)

		const login = formdata.get('login')
		const password = formdata.get('password')

		if (!(login && password)) {
			// TODO: Say something
			return
		}

		const response = await Authenticate(login as string, password as string)

		if (response) {
			navigate('/' + location.search)
		} else {
			toast(
				t(
					'errors.login.wrongPassword',
					'Неправильное имя пользователя или пароль.'
				),
				{
					type: 'error',
				}
			)
		}
	}

	// Check if user is logged in then redirect to cabinet
	useEffect(() => {
		;(async () => {
			const user = await GetCurrentUser()

			if (user) {
				navigate('/' + location.search)
				return
			}
		})()
	}, [])
	return (
		<div className='top'>
			<div id='bcall'>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '0 1rem',
					}}
				>
					<span>{t('login.loginPage.title', 'Вход')}</span>
					<LanguageSwitcher />
				</div>
				<form className='call_form' onSubmit={processForm}>
					<input
						type='text'
						name='login'
						placeholder={t('login.loginPage.loginPlaceholder', 'логин')}
						required
					/>
					<input
						type='password'
						name='password'
						placeholder={t('login.loginPage.passwordPlaceholder', 'пароль')}
						required
					/>
					<button type='submit'>{t('login.loginButtonText', 'Войти')}</button>
				</form>
			</div>
		</div>
	)
}
