import { DelNotification, GetNotifications, INotification, NotificationStatusMap, ReadNotification } from '@/shared/api'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import classNames from "classnames"
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { ImBin } from "react-icons/im"
import { animated, useTransition } from "react-spring"
import { ArrowSvg, IconsMailCheck } from "../../assets/svg/icons"
import ScrollBar from "../ScrollBar/ScrollBar"
import styles from "./NotificationWidget.module.scss"

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[5],
    zIndex: 200,
    fontFamily: 'Fira Sans',
    fontStyle: "normal",
    fontWeight: 500,
    padding: "10px 10px",
    borderRadius: "3px",
    fontSize: "13px",
    lineHeight: "16px"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgb(255 255 255 / 100%)',
  }
}));

export default function NotificationWidget() {
  const { t } = useTranslation();

  const notificationStatusMap = useMemo(() => {
    return {
      "delivered" : t("notificationStatus.delivered", "Доставлен"),
      "onTrainStation" : t("notificationStatus.onTrainStation", "Прибытие на станцию"),
      "containerOnRails" : t("notificationStatus.containerOnRails", "Отправка по ЖД"),
      "waitingForRails" : t("notificationStatus.waitingForRails", "Выпуск с таможни"),
      "customsClearance" : t("notificationStatus.customsClearance", "Подача документов на таможне"),
      "arrivalInPort" : t("notificationStatus.arrivalInPort", "Прибытие в порт"),
      "wentToSea" : t("notificationStatus.wentToSea", "Выход в море"),
      "uponTheSender" : t("notificationStatus.uponTheSender", "У отправителя"),
      "custom" : t("notificationStatus.unknown", "Custom"),
    }
  }, [t]);

  const [notifications, setNotifications] = useState<
    Array<INotification>
  >([]);

  const [selected, setSelected] = useState<Array<number>>([]);
  const [open, setOpen] = useState<boolean>(false);

  const readAllSelected = async () => {
    const response = await ReadNotification(selected);
    // TODO: do stuff on backend
    // let stuff =
    if (response) {
      setNotifications((notifications) =>
        notifications.map((item) => {
          const foundInResponse = response.find((r) => r === item.id);
          if (foundInResponse) {
            item.viewed = 1;
            return item;
          }
          return item;
        })
      );
    }


    setSelected([]);
  };

  const delAllSelected = async () => {
    const response = await DelNotification(selected);
    // TODO: do stuff on backend
    // let stuff = 
    if (response) {
      setNotifications((notifications) =>
        notifications.filter((i) => !response.find((r) => r === i.id))
      );
    }
    setSelected([]);

  };

  const delOneSelected = async (id: number) => {
    const response = await DelNotification([id]);

    if (response) {
      setNotifications((notifications) =>
        notifications.filter((i) => !response.find((r) => r === i.id))
      );
    }

  };



  const transitions = useTransition(open, {
    from: { opacity: 0, y: 200 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 200 },
    delay: 0,
  });

  useEffect(() => {
    (async () => {
      const response = await GetNotifications()

      if (response) {
        setNotifications(response);
      }

    })()
  }, [])

  return (
    <div className={styles.container}>
      {transitions(
        (style, item) =>
          item && (
            <animated.div
              className={styles.notificationsContainer}
              style={style}
            >
              <div className={styles.popupHeader}>
                <label>
                  <input
                    type="checkbox"
                    checked={
                      notifications.length !== 0 &&
                      notifications.length === selected.length
                    }
                    className={styles.checkbox}
                    onChange={(e) => {
                      const checked = e.currentTarget.checked;
                      if (checked) {
                        setSelected(notifications.map((item) => item.id));
                      } else {
                        setSelected([]);
                      }
                    }}
                  />
                  <span>{t("notificationsWidget.selectAll", "Выбрать все")}</span>
                </label>

                <div
                  className={classNames(styles.popupHeaderIcons, {
                    [styles.popupHeaderIconsInactive]: selected.length < 1,
                  })}
                >
                  <LightTooltip arrow placement="bottom" title={t("notificationsWidget.tooltip.delete", "Удалить")}>
                    <span><ImBin onClick={delAllSelected} /></span>
                  </LightTooltip>

                  <LightTooltip arrow placement="bottom" title={t("notificationsWidget.tooltip.setAsRead", "Отметить как прочитанное")}>
                    <span><IconsMailCheck onClick={readAllSelected} /></span>
                  </LightTooltip>

                </div>
              </div>
              <div className={styles.messagesContainer}>
                <ScrollBar>
                  {notifications.length > 0 ? (
                    notifications.map((item) => {
                      return (
                        <NotificationCard
                          key={item.id}
                          statusMap={notificationStatusMap}
                          isSelected={!!selected.find((i) => item.id === i)}
                          isRead={item.viewed === 1}
                          notification={item}
                          onClose={() => {
                            delOneSelected(item.id);
                            setSelected((selected) =>
                              selected.filter((i) => i !== item.id)
                            );
                          }}
                          onSelect={(vs) => {
                            const checked = vs.currentTarget.checked;

                            if (checked) {
                              setSelected([...selected, item.id]);
                            } else {
                              setSelected((selected) =>
                                selected.filter((i) => i !== item.id)
                              );
                            }
                          }}
                        />
                      );
                    })
                  ) : (
                    <div className={styles.noNotifications}>
                      {t("notificationsWidget.empty", "Уведомлений нет")}
                    </div>
                  )}
                </ScrollBar>
              </div>
            </animated.div>
          )
      )}

      <div
        className={classNames(styles.titleBlock, {
          [styles.isOpen]: open,
        })}
        onClick={() => {
          setOpen((v) => !v);
        }}
      >
        <div>
          {t("notificationsWidget.widgetTitle", "Уведомления")} {notifications.length > 0 && `(${notifications.length})`}
        </div>
        <ArrowSvg />
      </div>
    </div>
  );
}

interface NotificationCardProps {
  isSelected: boolean;
  onClose?: () => void;
  onSelect?: React.ChangeEventHandler<HTMLInputElement>;
  notification: INotification;
  isRead: boolean,
  statusMap: typeof NotificationStatusMap
}
export function NotificationCard(props: NotificationCardProps) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(styles.notificationCard, {
        [styles.notificationCardActive]: props.isRead,
      })}
    >
      <label>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={props.isSelected}
          onChange={props.onSelect}
        />
        <span>{props.notification.requestId !== '' && (<>№{props.notification.requestId}</>)}</span>
      </label>
      {props.notification.sender_name && <div className={styles.cardTitle}>
        {props.notification.sender_name}
      </div>}
      {props.notification.status === 'custom' ? (
        <>{props.notification.text}</>
      )
        : (<p>
          <Trans i18nKey="notificationsWidget.statusChanged" t={t}>
            Статус отправления {{ requestId: props.notification.requestId }} изменился на {{ status: props.statusMap[props.notification.status] }}
          </Trans>
        </p>
        )}

      <div className={styles.notificationFooter}>

        <span>
          {/* {props.notification.dateActualForSite && <>Дата актуальности: {format(new Date(props.notification.dateActualForSite), "d.MM.yy")}</>} */}
        </span>
        <span>{format(new Date(props.notification.created_at), "d.MM.yy H:mm")}</span>
      </div>
      <div className={styles.notificationClose} onClick={props.onClose}>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5685 0.0201416L4.84184 3.74681L1.11517 0.0201416L0.175171 0.960142L3.90184 4.68681L0.175171 8.41348L1.11517 9.35348L4.84184 5.62681L8.5685 9.35348L9.5085 8.41348L5.78184 4.68681L9.5085 0.960142L8.5685 0.0201416Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
}
