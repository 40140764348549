import { AuthForm } from '@/features/auth-form'
import { AppStoreButton, PlayMarketButton } from '@/shared/ui'
import styles from './styles.module.scss'

export function LoginPage() {
	return (
		<div className='main'>
			<div className={styles.container}>
				<div className='wr'></div>
				<AuthForm />
				<div
					style={{
						display: 'flex',
						gap: '0px',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<PlayMarketButton link='https://play.google.com/store/apps/details?id=tpp.parus.com' />
					<AppStoreButton link='https://apps.apple.com/app/парус-таможня-логистика/id1065047780' />
				</div>
			</div>
		</div>
	)
}
