import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { languagesSupport } from './constants/languages'
import { DropdownLanguageProps } from './model/types'
import style from './styles.module.scss'

export const LanguageSwitcher = ({
	className,
	...rest
}: DropdownLanguageProps) => {
	const { i18n } = useTranslation()
	const [language, setLanguage] = useState(i18n.language || 'ru')

	const handleLangChange = evt => {
		const lang = evt.target.value
		setLanguage(lang)
		i18n.changeLanguage(lang)
	}

	return (
		<select
			onChange={handleLangChange}
			value={language}
			className={classNames(style['select'], className)}
			{...rest}
		>
			{languagesSupport.map(language => (
				<option key={language} value={language}>
					{language.toUpperCase()}
				</option>
			))}
		</select>
	)
}
