import React from 'react'
import '../assets/css/cap.scss'
import '../assets/css/fontawesome.css'
// Import all weights used by the app
import '@fontsource/fira-sans-condensed'
import '@fontsource/fira-sans-condensed/200.css'
import '@fontsource/fira-sans/200.css'
import '@fontsource/fira-sans/300.css'
import '@fontsource/fira-sans/400.css'
import '@fontsource/fira-sans/600.css'
import '@fontsource/fira-sans/700.css'
import 'react-toastify/dist/ReactToastify.css'
import { App } from './App'

// Internationalization
import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import backend, { HttpBackendOptions } from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
	.use(detector)
	.use(backend)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init<HttpBackendOptions>({
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',

			// path to post missing resources, or a function
			// function(lng, namespace) { return customPath; }
			// the returned path will interpolate lng, ns if provided like giving a static path
			// addPath: '/locales/add/{{lng}}/{{ns}}',
			// reloadInterval:   1000 // defaults to false in ms
		},
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
		// lng: "ru", // For testing purposes
		supportedLngs: ['ru', 'en'],
		fallbackLng: 'ru',
		interpolation: {
			escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		},
	})

import reportWebVitals from '../reportWebVitals'
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'

import { createRoot } from 'react-dom/client'
import { Providers } from './providers'

createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<Providers>
			<App />
		</Providers>
	</React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
