import { parse1CDate, parse1CDateTime } from '../lib'
import { apiPath } from './constants/constants'
import type { IForwarderEmail, IHistoryItem, IHistoryItemRaw, INotification, ISendFeedback, IUser } from './model/types'


/**
 * Authenticates the user
 * @param login
 * @param password
 */
export async function Authenticate(login: string, password: string) {
  const params = new URLSearchParams({
    login,
    password,
  });

  const response = await fetch(apiPath + "user/auth?" + params, {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json: {
      status: string;
      user_id: string;
    } = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("user not found");
    return null;
  }
}

/**
 * Logout the user
 * @param login
 * @param password
 */
export async function Logout() {
  const response = await fetch(apiPath + "user/logout", {
    method: "POST",
    credentials: "include"
  });

  return response.ok;
}


/**
 * Gets current user
 * @param login
 * @param password
 */
export async function GetCurrentUser() {
  const response = await fetch(apiPath + "user/get", {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json: IUser = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("user not found");
    return undefined;
  } else {
    return undefined;
  }
}


export async function SetArchived(id: number, isArchived) {
  const endpoint = isArchived ? "archive" : "unarchive";
  const response = await fetch(apiPath + `history/${id}/${endpoint}/`, {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json: IHistoryItemRaw = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("user not found");
    return null;
  }
}


export async function GetHistory() {
  const params = new URLSearchParams({});

  const response = await fetch(apiPath + "history/get?" + params, {
    method: "GET",
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<IHistoryItemRaw> = await response.json();

    const result: Array<IHistoryItem> = json.map((item) => {
      const result: IHistoryItem = {
        ...item,
        created_at: new Date(item.created_at),
        date_actual_for_site: parse1CDateTime(item.date_actual_for_site),
        date_arrived_to_station: parse1CDate(item.date_arrived_to_station),
        date_konosament: parse1CDate(item.date_konosament),
        date_otpravkapojd: parse1CDate(item.date_otpravkapojd),
        date_podachagtd: parse1CDate(item.date_podachagtd),
        date_pributievport: parse1CDate(item.date_pributievport),
        date_vigruzkanasklad: parse1CDate(item.date_vigruzkanasklad),
        date_vihodvmore: parse1CDate(item.date_vihodvmore),
        date_vipuskgtd: parse1CDate(item.date_vipuskgtd),
        updated_at: parse1CDate(item.updated_at),
        readiness: parse1CDate(item.readiness),
        daysOnTheRoad: item.daysOnTheRoad ?? 0,
        raw: item,
      };
      
      return result;

    });
    return result;
  } else if (response.status === 401) {
    console.log("user not found");
    return null;
  }
}

export async function getCurrencies() {
  const response = await fetch(apiPath + "getCurrencies", {
    method: "GET"
  });

  if (response.ok) {
    const json: {
      eur: number;
      usd: number;
    } = await response.json();
    return json;
  } else {
    return undefined;
  }
}






export async function GetNotifications() {
  const params = new URLSearchParams({});

  const response = await fetch(apiPath + "notification/get?" + params, {
    method: "GET",
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<INotification> = await response.json();
    return json;  
  } else if (response.status === 401) {
    console.log("No notifications");
    return null;
  }
}

export async function SetNotificationRead(notificationId: number) {
  const response = await fetch(apiPath + `notification/read/${notificationId}`, {
    method: "GET",
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<Notification> = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("No notifications");
    return null;
  }
}



export async function ForwarderEmail(email: string) {
  const params = new URLSearchParams({
    email,
  });

  const response = await fetch(apiPath + "user/setForwarderEmail?" + params, {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json: IForwarderEmail = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("not found");
    return null;
  }
}



export async function SendFeedback(email: string, company: string, text: string) {
  const params = new URLSearchParams({
    email,
    company,
    text
  });

  const response = await fetch(apiPath + "sendFeedback?" + params, {
    method: "POST",
    credentials: "include"
  });

  if (response.ok) {
    const json: ISendFeedback = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("not found");
    return null;
  }
}



export async function DelNotification(id: Array<number>) {

  const response = await fetch(apiPath + "notification/notActual", {
    method: "POST",
    body: JSON.stringify(id),
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<number> = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("not found");
    return [];
  }
  return []
}

export async function ReadNotification(id: Array<number>) {

  const response = await fetch(apiPath + "notification/read", {
    method: "POST",
    body: JSON.stringify(id),
    credentials: "include"
  });

  if (response.ok) {
    const json: Array<number> = await response.json();
    return json;
  } else if (response.status === 401) {
    console.log("not found");
    return [];
  }
  return []
}