import { HTMLProps } from 'react'
import { useTranslation } from 'react-i18next'
import enLogo from './assets/google-play-badge-en.png'
import ruLogo from './assets/google-play-badge-ru.png'
interface PlayButtonProps extends HTMLProps<HTMLDivElement> {
	link?: string
}

export function PlayMarketButton(props: PlayButtonProps) {
	const { i18n } = useTranslation()

	const { link, ...rest } = props

	return (
		<a
			style={{
				width: '150px',
			}}
			href={link}
			target='_blank'
			rel='noreferrer'
		>
			<div {...rest}>
				<img
					style={{
						width: '100%',
					}}
					src={i18n.language === 'ru' ? ruLogo : enLogo}
					alt='Google Play'
				/>
			</div>
		</a>
	)
}
