import { DashboardPage, DetailsPage, LoginPage } from '@/pages'
import { pageRoutes } from '@/shared/constants'
import { Routes } from "react-router"
import { Route } from "react-router-dom"


export const Router = () => {
	return (
		<Routes>
			<Route path={pageRoutes.dashboard} element={<DashboardPage />} />
			<Route path={pageRoutes.login} element={<LoginPage />} />
			<Route path={pageRoutes.details + '/:id'} element={<DetailsPage />} />
		</Routes>
	)
}