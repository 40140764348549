import { Logout } from '@/shared/api'
import { pageRoutes } from '@/shared/constants'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styles from './styles.module.scss'
export const LogoutButton = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const logoutFn = async () => {
		await Logout()
		navigate(pageRoutes.login)
	}

	return (
		<div className={styles.button}>
			<button onClick={logoutFn}>{t('sidebar.logoutButton', 'Выход')}</button>
		</div>
	)
}
