import train from '@/assets/images/train.png'
import {
	IconsBoxUploadSvg,
	IconsCustomsSvg,
	IconsShipSvg,
	IconsUnloadingSvg,
	Inspection,
	Midk,
} from '@/assets/svg/icons'
import Arrow from '@/components/Arrow/Arrow'
import { ShowConfirmPopup } from '@/components/Popup/ConfirmPopup'
import { GetHistory, IHistoryItem, SetArchived } from '@/shared/api'
import { fdate, wasOnTheRoad } from '@/shared/lib'
import classNames from 'classnames'
import { Dispatch, Fragment, memo, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styles from './styles.module.scss'

/**
 * Check if the item was on the road
 * @param item
 * @returns
 */

export const TableRow = memo(
	({
		data,
		isOpen,
		setOpenRows,
		setData,
	}: {
		data: IHistoryItem
		isOpen: boolean
		setOpenRows: React.Dispatch<React.SetStateAction<number[]>>
		setData: Dispatch<SetStateAction<IHistoryItem[]>>
	}) => {
		const item = data

		const { t } = useTranslation()

		return (
			<Fragment>
				<tr
					className={classNames(styles.tableRow, {
						[styles.active]: isOpen,
					})}
					onClick={() => {
						setOpenRows(openRows => {
							if (openRows.includes(item.id)) {
								return openRows.filter(n => n !== item.id)
							} else {
								return [...openRows, item.id]
							}
						})
					}}
				>
					<td>
						{/* arrow svg icons table opening   */}
						<Arrow isOpen={isOpen} />
					</td>
					{/* номер транзит */}
					<td colSpan={2}>{item.request_number}</td>
					{/* номер клиента */}
					<td colSpan={2}>{item.client_number}</td>
					{/* описание груза */}
					<td colSpan={2}>{item.description}</td>
					{/* отправитель */}
					<td colSpan={2}>{item.sender_name}</td>
					{/* номер ктк */}
					<td colSpan={2}>{item.container_id}</td>
					{/* готовность */}
					<td colSpan={2} style={{ textAlign: 'center' }}>
						{fdate(item.readiness)}
					</td>
					{/* выход в море */}
					<td colSpan={2} style={{ textAlign: 'center' }}>
						{fdate(item.date_vihodvmore)}
						{/* <i className="tpp-icons">customs</i> */}
					</td>
					{/* прибытие */}
					<td colSpan={2} style={{ textAlign: 'center' }}>
						{fdate(item.date_pributievport)}
						{/* <i className="tpp-icons">customs</i> */}
					</td>
				</tr>

				<tr className='tdet' style={{ display: isOpen ? 'table-row' : 'none' }}>
					{isOpen && (
						<td colSpan={18}>
							<table width='100%'>
								<tbody>
									<tr>
										<td valign='bottom' align='left'>
											<div>
												{t(
													'dashboard.detailHeaders.placeFrom',
													'Место отправления'
												)}
												:
											</div>
										</td>
										<td className={styles.rightTd}>{item.place_from || '-'}</td>

										<td valign='bottom' align='left'>
											<div
												dangerouslySetInnerHTML={{
													__html:
														t(
															'dashboard.detailHeaders.dateVihodvmore',
															'Выход в&nbsp;море'
														) + ':',
												}}
											/>
										</td>
										<td className={styles.rightTd}>
											{fdate(item.date_vihodvmore) || '-'}
										</td>

										<td valign='bottom' align='left'>
											<div>
												{t(
													'dashboard.detailHeaders.dateOtpravkapojd',
													'Отправка ЖД'
												)}
												:
											</div>
										</td>
										<td className={styles.rightTd}>
											{fdate(item.date_otpravkapojd) || '-'}
										</td>
									</tr>
									<tr>
										<td valign='bottom' align='left'>
											<div>
												{t(
													'dashboard.detailHeaders.destinationPort',
													'Порт прибытия'
												)}
												:
											</div>
										</td>
										<td className={styles.rightTd}>
											{item.destination_port || '-'}
										</td>

										<td valign='bottom' align='left'>
											<div
												dangerouslySetInnerHTML={{
													__html:
														t(
															'dashboard.detailHeaders.portArrivalDate',
															'Прибытие в&nbsp;порт'
														) + ':',
												}}
											/>
										</td>
										<td className={styles.rightTd}>
											{fdate(item.date_pributievport) || '-'}
										</td>

										<td valign='bottom' align='left'>
											<div
												dangerouslySetInnerHTML={{
													__html:
														t(
															'dashboard.detailHeaders.dateArrivedToStation',
															'Прибытие на&nbsp;станцию'
														) + ':',
												}}
											/>
										</td>
										<td className={styles.rightTd}>
											{fdate(item.date_arrived_to_station) || '-'}
										</td>
									</tr>
									<tr>
										<td valign='bottom' align='left'>
											<div>
												{t(
													'dashboard.detailHeaders.destinationCity',
													'Город назначения'
												)}
												:
											</div>
										</td>
										<td className={styles.rightTd}>
											{item.destination_city || '-'}
										</td>
										<td valign='bottom' align='left'>
											<div>
												{t(
													'dashboard.detailHeaders.dateKonosament',
													'Получение коносамента'
												)}
												:
											</div>
										</td>
										<td className={styles.rightTd}>
											{fdate(item.date_konosament) || '-'}
										</td>
										<td valign='bottom' align='left'>
											<div>
												{t(
													'dashboard.detailHeaders.dateVigruzkanasklad',
													'Выгрузка'
												)}
												:
											</div>
										</td>
										<td className={styles.rightTd}>
											{fdate(item.date_vigruzkanasklad) || '-'}
										</td>
									</tr>
									<tr>
										<td valign='bottom' align='left'>
											<div>
												{t('dashboard.detailHeaders.gtdNumber', 'Номер ДТ')}:
											</div>
										</td>
										<td className={styles.rightTd}>{item.gtd_number || '-'}</td>
										<td valign='bottom' align='left'>
											<div>
												{t(
													'dashboard.detailHeaders.datePodachagtd',
													'Подача ДТ'
												)}
												:
											</div>
										</td>
										<td className={styles.rightTd}>
											{fdate(item.date_podachagtd) || '-'}
										</td>
										{item.daysOnTheRoad ? (
											<td
												className={styles.rightTd}
												rowSpan={2}
												colSpan={2}
												align='center'
												valign='bottom'
											>
												<span
													dangerouslySetInnerHTML={{
														__html: t('dashboard.detailHeaders.daysOnTheRoad', {
															count: item.daysOnTheRoad ?? 0,
														}),
													}}
												/>
											</td>
										) : (
											<td></td>
										)}
									</tr>
									<tr>
										<td valign='bottom' align='left'>
											<div>
												{t(
													'dashboard.detailHeaders.restrictedRetrieval',
													'Запрет'
												)}
												:
											</div>
										</td>
										<td className={styles.rightTd}>
											{item.restricted_retrieval === 0 ? 'Нет' : 'Да'}
										</td>
										<td valign='bottom' align='left'>
											<div>
												{t('dashboard.detailHeaders.dateVipuskgtd', 'Выпуск')}:
											</div>
										</td>
										<td className={styles.rightTd}>
											{fdate(item.date_vipuskgtd) || '-'}
										</td>
									</tr>
								</tbody>
							</table>
							<table width='100%'>
								<tbody className={styles.containerTflow}>
									<tr className='tflow'>
										<td
											className={classNames({
												active: item.readiness,
											})}
										>
											<div className='iconContainer'>
												<IconsBoxUploadSvg />
											</div>

											<div className='data'>
												{fdate(item.readiness)}
												<br />
												{t(
													'dashboard.statusCaptions.cargoReadiness',
													'Готовность груза'
												)}
											</div>
										</td>
										<td
											className={classNames({
												active: item.date_vihodvmore !== null,
											})}
										>
											<div className='iconContainer'>
												<IconsShipSvg />
											</div>

											<div className='data'>
												{fdate(item.date_vihodvmore)}
												<br />
												<span
													dangerouslySetInnerHTML={{
														__html: t(
															'dashboard.statusCaptions.dateExitToSea',
															'Выход в&nbsp;море'
														),
													}}
												/>
											</div>
										</td>
										<td
											className={classNames({
												active: fdate(item.date_pributievport),
											})}
										>
											<div className='iconContainer'>
												<IconsShipSvg />
											</div>

											<div className='data'>
												{fdate(item.date_pributievport)}
												<br />
												<span
													dangerouslySetInnerHTML={{
														__html: t(
															'dashboard.statusCaptions.portArrivalDate',
															'Прибытие в&nbsp;порт'
														),
													}}
												/>
											</div>
										</td>
										<td
											className={classNames({
												active: fdate(item.date_podachagtd),
											})}
										>
											<div className='iconContainer'>
												<IconsCustomsSvg />
											</div>

											<div className='data'>
												{fdate(item.date_podachagtd)}
												<br />
												{t('dashboard.statusCaptions.dateOfDT', 'Подача ДТ')}
											</div>
										</td>
										{item.midk === 1 ? (
											<td
												className={classNames({
													active: item.midk,
												})}
											>
												<div className='iconContainer'>
													<Midk />
												</div>

												<div className='data'>
													{/* {item.midk} */}
													<br />
													{t('dashboard.statusCaptions.midk', 'МИДК')}
												</div>
											</td>
										) : (
											<td></td>
										)}
										{item.inspection === 1 ? (
											<td
												className={classNames({
													active: item.inspection,
												})}
											>
												<div className='iconContainer'>
													<Inspection />
												</div>

												<div className='data'>
													{/* {item.inspection} */}
													<br />
													{t('dashboard.statusCaptions.inspection', 'Досмотр')}
												</div>
											</td>
										) : (
											<td></td>
										)}

										<td
											className={classNames({
												active: fdate(item.date_vipuskgtd),
											})}
										>
											<div className='iconContainer'>
												<IconsCustomsSvg />
											</div>

											<div className='data'>
												{fdate(item.date_vipuskgtd)}
												<br />
												{t('dashboard.statusCaptions.dateOfExit', 'Выпуск')}
											</div>
										</td>
										<td
											className={classNames({
												active: wasOnTheRoad(item),
											})}
										>
											<div className='iconContainer'>
												<img src={train}></img>
											</div>

											<div className='data'>
												{fdate(item.date_otpravkapojd)}
												<br />
												{t(
													'dashboard.statusCaptions.dateSentToRailroad',
													'Отправка ЖД'
												)}
											</div>
										</td>
										{wasOnTheRoad(item) && item.distance !== 0 && (
											<td className={classNames('disl', 'active')}>
												<div className='iconContainer'>
													<img src={train} style={{ opacity: 0 }}></img>
												</div>

												<div className='data'>
													{item?.dislocation ?? '-'}
													<br />
													{item?.distance?.toLocaleString('ru') ?? '-'}
												</div>
											</td>
										)}

										{/* <td className="disl">
            <i className="tpp-icons">train</i>
            <div>&nbsp;</div>
          </td> */}
										<td
											className={classNames({
												active: fdate(item.date_arrived_to_station),
											})}
										>
											<div className='iconContainer'>
												<img src={train}></img>
											</div>

											<div className='data'>
												{fdate(item.date_arrived_to_station)}
												<br />
												<span
													dangerouslySetInnerHTML={{
														__html: t(
															'dashboard.statusCaptions.dateArrivedToStation',
															'Прибытие на&nbsp;станцию'
														),
													}}
												/>
											</div>
										</td>
										<td
											className={classNames({
												active: fdate(item.date_vigruzkanasklad),
											})}
										>
											<div className='iconContainer'>
												<IconsUnloadingSvg />
											</div>

											<div className='data'>
												{fdate(item.date_vigruzkanasklad)}
												<br />
												{t(
													'dashboard.statusCaptions.dateOfUnloading',
													'Выгрузка'
												)}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div
								style={{
									width: '100%',
									display: 'flex',
								}}
							>
								{!item.archive && (
									<button
										style={{
											backgroundColor: item.client_archived
												? '#00a8ff'
												: '#007bff',
											color: 'white',
											border: 'none',
											padding: '10px',
											cursor: 'pointer',
										}}
										onClick={async () => {
											const sure = await ShowConfirmPopup({
												cancelText: t('dashboard.cancelButton', 'Отмена'),
												okText: t('dashboard.okButton', 'Ок'),
												text: item.client_archived
													? t(
															'dashboard.unArchiveConfirm',
															'Вы уверены, что хотите вернуть из архива?'
													  )
													: t(
															'dashboard.archiveConfirm',
															'Вы уверены, что хотите отправить в архив?'
													  ),
												title: t('dashboard.confirmTitle', 'Подтверждение'),
												closeOnBackgroundClick: true,
											})

											if (!sure) return

											const result = await SetArchived(
												item.id,
												!item.client_archived
											)
											const response = await GetHistory()
											if (response) {
												setData(response)
											}
											toast(
												result
													? t('dashboard.toast.success', 'Успешно')
													: t('dashboard.toast.error', 'Ошибка'),
												{
													type: 'success',
												}
											)
										}}
									>
										{item.client_archived
											? t('dashboard.unArchiveButton', 'Вернуть из архива')
											: ''}
										{item.client_archived
											? ''
											: t('dashboard.archiveButton', 'Отправить в архив')}
									</button>
								)}
							</div>
						</td>
					)}
				</tr>
			</Fragment>
		)
	}
)
